import logo from './askona_logo_app.svg';
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CarouselComponent from './CarouselComponent.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import ruTranslation from './translation/ru.json';
import enTranslation from './translation/en.json';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

// Инициализация i18n
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ru: { translation: ruTranslation },
  },
  lng: 'ru',
  interpolation: { escapeValue: false },
});

function App() {
  // Использование хука для перевода
  const { t } = useTranslation();

  // Состояние для активного индекса и общего количества шагов в карусели
  const [activeIndex, setActiveIndex] = useState(0);
  const totalSteps = 8;

  // Состояние для отслеживания выбранного языка
  const [language, setLanguage] = useState('ru');
  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage); // Изменение языка при выборе
  };

  // Функции для переключения шагов в карусели
  const handlePrev = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const handleNext = () => {
    if (activeIndex < totalSteps - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  return (
    <Container className="App mt-8">
      <Row className="justify-content-md-center">
        {/* Контент */}
        <Col md={8}>
          <div className="rounded p-4 border form-container ">
            {/* Логотип и заголовок */}
            {/* Кнопки для переключения языка в правом верхнем углу */}
            <div className="language-switcher d-flex justify-content-end">
              <button
                onClick={() => handleLanguageChange('ru')}
                className={`${language === 'ru' ? 'btn-primary' : 'btn-secondary'}`}
                disabled={language === 'ru'}>
                🇷🇺 Русский
              </button>
              <button
                onClick={() => handleLanguageChange('en')}
                className={`${language === 'en' ? 'btn-primary' : 'btn-secondary'}`}
                disabled={language === 'en'}>
                🇺🇸 English
              </button>
            </div>

            <img src={logo} className="App-logo logo" alt="logo" />
            <h2 className='title'>{t('app.title')}</h2>

            
            <div className='text-in-container'>
            <p className='title'>
                Если у вас есть вопросы по работе приложения, то свяжитесь с нами через  
                <a href="https://t.me/askona_help_bot" target="_blank" rel="noopener noreferrer">
                <img src="/tg_icon.svg" alt="Telegram Icon" /> Telegram бота</a> (@askona_help_bot).</p>
                
                <h3 className='title'>{t('app.title_stored_data')}</h3>
              {/* Перечень хранимых данных */}
              <ul>
                <li>{t('app.stored_data_phone')}</li>
                <li>{t('app.stored_data_username')}</li>
                <li>{t('app.stored_data_birthday')}</li>
                <li>{t('app.stored_data_reports')}</li>
              </ul>

              {/* Компонент карусели */}
              <CarouselComponent
                activeIndex={activeIndex}
                handlePrev={handlePrev}
                handleNext={handleNext}
                totalSteps={totalSteps}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
