// Импорты библиотек и стилей
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import React from 'react';
import { useTranslation } from 'react-i18next';

function CarouselComponent({ activeIndex, handlePrev, handleNext, totalSteps }) {
    const { t } = useTranslation(); // Хук для перевода

    // Массив шагов, заменен на ключи для перевода
    const steps = [
        'removal_instructions_step1',
        'removal_instructions_step2',
        'removal_instructions_step3',
        'removal_instructions_step4',
        'removal_instructions_step5',
        'removal_instructions_step6',
        'removal_instructions_step7',
        'removal_instructions_step8',
    ];
    const pathToImage = t('app.path_to_tutorial') + (activeIndex + 1) + '.png';

    return (
        <div className="container-сarousel">
            <h3 className='title'>{t('app.title_removal_instructions')}</h3>
            <h5 className='title'>{t('app.title_all_steps')}</h5>
            <ul>
                {/* Вывод шагов из перевода */}
                {steps.map((step, index) => (
                    <li key={index}>{t(`app.${step}`)}</li>
                ))}
            </ul>
            <p className='warning-text'>{t('app.warning_about_delete')}</p>
            <hr />
            {/* Заголовок из перевода */}
            {/* Картинка шага */}
            <h5 className='title'>{t('app.title_step_by_step')}</h5>
            <div className="card-сarousel">
                
                
                <img
                    className="img-step"
                    src={pathToImage}
                    alt={`Шаг ${activeIndex + 1}`} />
                <div className="content">
                    {/* Текущий шаг из перевода */}
                    <p>{t(`app.${steps[activeIndex]}`)}</p>

                    <div className="buttons">
                        {/* Кнопка "Назад" и "Вперед" из перевода */}
                        {activeIndex !== 0 && (
                            <button onClick={handlePrev}>{t('app.prev_step')}</button>
                        )}
                        {activeIndex !== totalSteps - 1 && (
                            <button onClick={handleNext}>{t('app.next_step')}</button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CarouselComponent;
